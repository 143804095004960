import React from 'react';
import { Field, ErrorMessage, useField } from 'formik';
import TextError from '../TextError';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import './_index.scss';
import { useTranslation } from 'react-i18next';

function Input({
  label,
  name,
  className,
  errorMessage,
  color,
  isDisabled,
  required,
  leftTag,
  rightTag,
  rightIcon,
  leftIcon,
  tagClassName,
  placeHolder,
  labelDescription,
  helper,
  error,
  ...rest
}) {
  const { i18n, t } = useTranslation();
  const { language } = i18n;

  return (
    <div className={`form-control ${className} ${language === 'ar' && 'arabic-form-control'}`}>
      {label && (
        <>
          <label htmlFor={name} className={language === 'ar' && 'arabic-label'}>
            {required &&
              (language === 'ar' ? (
                <>
                  <span className="required-field">*</span>
                  <p>{label}</p>
                </>
              ) : (
                <>
                  <p>{label}</p> <span className="required-field">*</span>
                </>
              ))}
          </label>
        </>
      )}
      <div className="field-wrapper">
        {
          <div
            className={`field ${language === 'ar' && `arabic-field`} ${
              error && 'field-error-style'
            }`}
          >
            {leftTag && <span className={tagClassName}>{leftTag}</span>}
            {leftIcon && leftIcon}
            <Field
              id={name}
              name={name}
              disabled={isDisabled}
              style={{ color: color ? color : '' }}
              placeholder={placeHolder}
              {...rest}
            />
            {rightTag && <span className={tagClassName}>{rightTag}</span>}
            {rightIcon && rightIcon}
          </div>
        }
        {error && error?.trim().length > 0 ? (
          <p className={language === 'ar' ? 'arabic-error  error' : 'error'}>
            {language === 'ar' ? (
              <>
                {t(error)} <ReportProblemIcon />
              </>
            ) : (
              <>
                {<ReportProblemIcon />} {t(error)}
              </>
            )}
          </p>
        ) : (
          <ErrorMessage component={TextError} name={name} />
        )}
        {helper && (
          <p className={language === 'ar' ? 'arabic-helper helper' : 'helper'}>{helper}</p>
        )}{' '}
      </div>
    </div>
  );
}

export default Input;
