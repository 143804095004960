import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import ImWallet from '../../assets/img/icons/wallet.svg';
import { useTranslation } from 'react-i18next';
import {CircularProgressWithLabel} from '../index';
import {getUserFreeDuration} from '../../redux/slices/newProgress';
import {useDispatch, useSelector} from 'react-redux';

const Index = ({points, inputValue,hasFreeHours}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
    const { freeDurationPercentage } = useSelector((state) => state.newProgress);
  useEffect(() => {
    if(hasFreeHours) {
      dispatch(getUserFreeDuration(hasFreeHours));
    }
  }, [hasFreeHours]);
  return (
      <div className={inputValue ? 'search-show' : 'wallet-widget'}
           onClick={(e) => (window.location = '/porte-monnaie')}>
        {hasFreeHours &&
          <CircularProgressWithLabel value={freeDurationPercentage}/>
        }
        <img alt="wallet-icon" src={ImWallet}/>
        <div>
          <span className="title">{t("votre solde")}</span>
          {(points !== 1 && points <= 10 ) ? (
          <span className="amount">{points} {t("Pts")}</span> ):(
            <span className="amount">{points} {t("PtsAr")}</span> )}
        </div>
      </div>
  );
};

Index.propTypes = {
  points: PropTypes.number,
};

export default Index;
