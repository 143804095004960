import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { days } from '../../../utilities/constants';
import Modal from '../../../components/Modal/Modal';
import HessatyModal from '../../../components/common/SessionModal/HessatyModal';
import SessionModal from '../../../components/common/SessionModal';
import EventResult from '../EventResult';
import { useSelector } from 'react-redux';
import {extractMonthFromWeek} from '../../../utilities/translateHelper';
import {extractEventDay} from '../../../utilities/translateHelper';

const CalenderMobile = ({
  events,
  week,
  currentWeek,
  setOffset,
  width,
  offset,
  setOpenLink,
  setOpenSnackBar,
  openSnackBar,
  open,
  openLink,
  modalButton,
  currentSession,
  profSon,
  versPilote,
  startEvent,
  joinEvent,
  openEventModal,
  onCloseModal,
  buyHessaty,
  disableBuy,
  studentLevels,
  divisionId,

}) => {
  const { i18n,t } = useTranslation();
  let currentDayIndex;
  const {informations} = useSelector((state) => state.user);
  const { joinResult, sessionLink, joinLoading, errorSession } = useSelector(
    (state) => state.sessions
  );
  const { error } = useSelector((state) => state.offers);
  const sessionPilote = currentSession?.event?.versPilote;
  const offerSecondTirm = informations?.offers?.find(
      (offer) =>
          (offer.group.id === 107  || offer.group.id === 112)
  )
  const isallowedProfSon = (profSon || offerSecondTirm) && !sessionPilote;
  const isallowedPilote = versPilote && currentSession?.event?.versPilote;

  const isVersPiloteSixDegree = versPilote && (divisionId === 31);

  let currentTime = new Date();
  currentTime = currentTime.getHours() + ":" + ("00" + currentTime.getMinutes()).slice(-2);
  const mobileEvents = (day) => {
    return events.filter(function (event) {
      if (
        offset ? event.day === day.index : event.day >= currentDayIndex && event.day === day.index
      ) {
        return event;
      }
      return null;
    });
  };

  
  const translateWeekDateCalender = (currentWeek) => {
    const currentWeekArr = extractMonthFromWeek(currentWeek);
     if(currentWeekArr){
       return currentWeekArr[0] + t(currentWeekArr[1]) + " - " + currentWeekArr[2] + t(currentWeekArr[3]) + " " + currentWeekArr[4]
     }
  };

  const translateEventDate= (day) => {
    const eventDay = extractEventDay(day);
     if(eventDay){
       return eventDay[0] + t(eventDay[1]) + " " + eventDay[2] 
     }
  };

  return (
    <div className="calender-mobile">
      <div className="calender-mobile-header">
        <div className="changeWeekMobile" style={{ width: width }}>
          <div className="headerDateMobile">
            <ArrowBackIosIcon
              className="headerIconMobile"
              onClick= {() => i18n.language === 'ar' ? setOffset((prevCount) => prevCount + 1) : setOffset((prevCount) => prevCount - 1)}
              />
            <Typography variant="h5" className="headerDateTextMobile">
              {translateWeekDateCalender(currentWeek?.now)}
            </Typography>
            <ArrowBackIosIcon
              style={{ transform: 'rotate(180deg)' }}
              className="headerIconMobile"
              onClick={() =>  i18n.language === 'ar' ? setOffset((prevCount) => prevCount - 1) : setOffset((prevCount) => prevCount + 1)}
              />
          </div>
        </div>
        <div className="weekMobile" style={{ width: width }}>
          {week &&
            Object.keys(week)?.map((key, index) => {
              if (week[key].currentDay) {
                currentDayIndex = week[key].id;
              }
              return (
                <div
                  className={
                    week[key].currentDay ? 'weekDayMobile current-weekDay' : 'weekDayMobile'
                  }
                >
                  <div className="weekDayNameMobile">{week[key].name[0]}</div>
                  <div
                    className={
                      week[key].currentDay ? 'weekDayNumberMobile current' : 'weekDayNumberMobile'
                    }
                  >
                    {week[key].day.substring(0, week[key].day.indexOf(' '))}
                  </div>
                </div>
              );
            })}
        </div>
      </div>{' '}
      <div className='levels-container'>
      {studentLevels && !!studentLevels.length && (
        <div className="student-levels">
          {studentLevels &&
            studentLevels.map((studentLevel) => {
              return (
                <div className="levels-card">
                  <div className="subject-name">{studentLevel?.subject?.name}</div>
                  <div className="student-level">{studentLevel?.student_level?.name}</div>
                </div>
              );
            })}
        </div>
      )}
      </div>
      {days.map((day, dayIndex) => {
        const existEvents = [];
        let eventDay;
        if (events && mobileEvents(day).length) {
          for (let [index, session] of mobileEvents(day).entries()) {
            const inProgressSession =(currentDayIndex==session.day)&&session.event.start_time <= currentTime && session.event.end_time >= currentTime;
            eventDay = session.event.dayDate;
            if (session) {
              existEvents.push(
                <div
                  className="event"
                  onClick={() => openEventModal(session)}
                  style={{
                    borderColor: session.disabled && '#A8A8A8',
                    boxShadow: session.disabled && 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                  }}
                >
                  <div className="event-header">
                    <img src={session.subject.thumbnail} className="event-subject-image" />
                    <div className="event-name">
                      {session.name.length > 60 ? session.name.substr(0, 60) + '...' : session.name}
                      <div className="event-time">
                        <div className="time">{ i18n.language === 'ar' ? session.event.end_time : session.event.start_time }</div>
                        <div> {t('à')}</div>
                        <div className="time">{i18n.language === 'ar' ? session.event.start_time : session.event.end_time}</div>
                      </div>
                    </div>
                    <div className="event-status">
                      <div
                        className={
                          inProgressSession ? 'event-status-in-progress' : !session.disabled ? 'event-status-text' : 'event-status-text disabled '
                        }
                      >
                        {inProgressSession ? t('En Cours') : !session.disabled ? t('Bientôt') : t('Terminée')}
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          }

          return (
            <div className="calender-mobile-container">
              <div className="event-card-header">
                <div className="event-day">{translateEventDate(eventDay)}</div>
                <div className="event-day-name">
                  {currentDayIndex === dayIndex && t("Aujourd hui")}
                </div>
                <div className="event-day-name">{currentDayIndex + 1 === dayIndex && t('Demain')}</div>
              </div>
              {existEvents}
            </div>
          );
        }
      })}
      {open && (
        <Modal
          onCloseModal={onCloseModal}
          component={
            currentSession?.event?.chapterId && !currentSession.event?.has_hessaty_chapter && !isallowedProfSon && !isallowedPilote && !isVersPiloteSixDegree && !offerSecondTirm
            ? HessatyModal
            : SessionModal
          }
          openModal={open}
          title={t('Séance en direct')}
          modalAction={
            currentSession && !currentSession.participated
            ? currentSession?.event?.chapterId && !currentSession?.event?.has_hessaty_chapter && !isallowedProfSon && !isallowedPilote && !isVersPiloteSixDegree && !offerSecondTirm 
              ? buyHessaty
              : joinEvent
            : startEvent
          }
          disableBuy={disableBuy}
          modalActionName={modalButton}
          modalProps={currentSession}
          modalWidth={'xs'}
        />
      )}
      <EventResult
        join={joinResult}
        link={sessionLink}
        setOpenSnackBar={setOpenSnackBar}
        setOpenLink={setOpenLink}
        open={openLink}
        openSnackBar={openSnackBar}
        loading={joinLoading}
        error={currentSession?.chapterId ? error : errorSession}
      />
    </div>
  );
};
export default CalenderMobile;
