import React, { useEffect, useState } from 'react';
import { ViewTitle } from '../../components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import VideoReportsResponsiveTable from '../../components/ResponsiveTable/VideoReportsResponsiveTable';
import Card from '@material-ui/core/Card';
import { getReportVideosHistory } from '../../redux/slices/courses';

const VideoReportsHistory = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const { videoReports } = useSelector((state) => state.courses);

  useEffect(() => {
    dispatch(getReportVideosHistory());
  }, []);

  useEffect(() => {
    if (videoReports) {
      setData(videoReports || []);
    }
  }, [videoReports]);

  const headers = [
    { accessor: 'id', label: t('ID'), show: true },
    { accessor: 'reason', label: t('Raison'), show: true },
    { accessor: 'reasonId', label: t('reasonId'), show: false },
    { accessor: 'report', label: t('Rapport'), show: true },
    { accessor: 'content', label: t('Contenu'), show: true },
    { accessor: 'contentId', label: t('contentId'), show: true },
    { accessor: 'date', label: t('Date'), type: 'date', show: true },
    { accessor: 'status', label: t('Statut'), show: true },
    { accessor: 'is_confirmed', label: t('Actions'), show: true },
  ];

  return (
    <>
      <ViewTitle title={t('Rapports')} />
      <Card className="ta-card">
        <div className="ta-column right-margin">
          <div className="child-full">
            <VideoReportsResponsiveTable cols={headers} rows={data || []} />
          </div>
        </div>
      </Card>
    </>
  );
};

export default VideoReportsHistory;
