import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Calender from './Desktop/Calender';
import ViewTitle from '../../components/ViewTitle';
import { useDispatch, useSelector } from 'react-redux';
import { getEventLink, getJoinResult, getSessions } from '../../redux/slices/sessions';
import Skeleton from '@material-ui/lab/Skeleton';
import WindowDimensions from '../../utilities/windowDimenssions';
import CalenderMobile from './Mobile/CalenderMobile';
import { buyOffer } from '../../redux/slices/offers';
import { calenderMobileWidth } from '../../utilities/constants';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { formatMessages } from '../../utilities/translateHelper';
import { openModal } from '../../redux/slices/modals';

const Index = () => {
  const { t } = useTranslation();
  const { informations } = useSelector((state) => state.user);
  const { permissions } = useSelector((state) => state.user);
  const { buyResult, loadingId, error } = useSelector((state) => state.offers);
  const { sessions, isLoading, sessionUpdated, joinResult } = useSelector(
    (state) => state.sessions
  );
  const profSon = permissions && permissions.isProfSon;
  const versPilote = permissions && permissions.versPilote;
  const [offset, setOffset] = useState(0);
  const [open, setOpenModal] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [disableBuy, setDisableButton] = useState(false);
  const [modalButton, setModalButton] = useState(false);
  const [openLink, setOpenLink] = useState(false);
  const [currentSession, setCurrentSession] = useState();
  const { width } = WindowDimensions();
  const haveEmail = !!informations?.email;
  const ModalButton = (session) => {
    const sessionPilote = session?.event.versPilote;
    const isallowedPilote = versPilote && session?.event?.versPilote;
    const offerSecondTirm = informations?.offers?.find(
      (offer) => offer.group.id === 107 || offer.group.id === 112
    );
    const isallowedProfSon = (profSon || offerSecondTirm) && !sessionPilote;
    //this check is permenant to be removed when add config to hessaty offer
    const isVersPiloteSixDegree = versPilote && informations?.division_id === 31;
    return !session?.disabled || session?.event?.chapterId
      ? !session?.participated
        ? session?.event?.chapterId &&
          !session?.event?.has_hessaty_chapter &&
          !isallowedProfSon &&
          !isallowedPilote &&
          !isVersPiloteSixDegree
          ? //&& !offerSecondTirm
            t('Acheter')
          : !session?.disabled
          ? t('Participer')
          : 'Fermer'
        : !session?.disabled
        ? t('Commencer')
        : 'Fermer'
      : 'Fermer';
  };
  const buyHessaty = async () => {
    setDisableButton(true);
    await dispatch(
      buyOffer(currentSession.hessatyId, 1, currentSession?.event?.chapterId, currentSession.id)
    );
    setOpenSnackBar(true);
    setOpenModal(false);
  };
  const onCloseModal = () => {
    setOpenModal(false);
  };
  const openEventModal = async (session) => {
    if (!haveEmail) {
      await dispatch(openModal('add-email-modal'), { haveEmail });
    } else {
      const modalButton = ModalButton(session);
      await setCurrentSession(session);
      setModalButton(modalButton);
      setOpenModal(true);
      setDisableButton(false);
    }
  };
  const joinEvent = async (event, session) => {
    await dispatch(getJoinResult(session.id, session?.subject?.domain?.id));
    setOpenSnackBar(true);
    setOpenModal(false);
  };
  const startEvent = async (event, session) => {
    setOpenLink(false);
    await dispatch(getEventLink(session.event?.id));
    setOpenSnackBar(true);
    setOpenLink(true);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSessions(offset));
  }, [dispatch, offset, sessionUpdated]);
  if (isLoading && !joinResult) {
    return (
      <>
        <Skeleton variant="text" width={'10%'} />
        <br />
        <div className="calender">
          <Skeleton variant="rect" width={'100%'} height={100} />
          <br />
          <br />
          <br />
          <Skeleton variant="rect" width={'100%'} height={1000} />
        </div>
      </>
    );
  } else {
    if (width > calenderMobileWidth) {
      return (
        <div>
          <ViewTitle title={t('En direct')} />
          <Calender
            week={sessions.days}
            events={sessions.events}
            currentWeek={sessions.current_week}
            setOffset={setOffset}
            haveEmail={haveEmail}
            studentLevels={sessions.student_levels}
            openModal={setOpenModal}
            setOpenSnackBar={setOpenSnackBar}
            open={open}
            openSnackBar={openSnackBar}
            modalButton={modalButton}
            setModalButton={setModalButton}
            openLink={openLink}
            setOpenLink={setOpenSnackBar}
            currentSession={currentSession}
            setCurrentSession={setCurrentSession}
            profSon={profSon}
            versPilote={versPilote}
            joinEvent={joinEvent}
            startEvent={startEvent}
            openEventModal={openEventModal}
            onCloseModal={onCloseModal}
            buyHessaty={buyHessaty}
            disableBuy={disableBuy}
            divisionId={informations?.division_id}
          />
        </div>
      );
    } else {
      return (
        <div>
          <CalenderMobile
            week={sessions.days}
            events={sessions.events}
            currentWeek={sessions.current_week}
            haveEmail={haveEmail}
            setOffset={setOffset}
            width={width}
            studentLevels={sessions.student_levels}
            offset={offset}
            openModal={setOpenModal}
            setOpenSnackBar={setOpenSnackBar}
            open={open}
            openSnackBar={openSnackBar}
            modalButton={modalButton}
            setModalButton={setModalButton}
            openLink={openLink}
            setOpenLink={setOpenSnackBar}
            currentSession={currentSession}
            setCurrentSession={setCurrentSession}
            profSon={profSon}
            versPilote={versPilote}
            startEvent={startEvent}
            joinEvent={joinEvent}
            openEventModal={openEventModal}
            onCloseModal={onCloseModal}
            buyHessaty={buyHessaty}
            disableBuy={disableBuy}
            divisionId={informations?.division_id}
          />
          <Snackbar
            open={openSnackBar}
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: 'Bottom',
              horizontal: 'center',
            }}
            onClose={() => setOpenSnackBar(false)}
          >
            <Alert severity={error ? 'warning' : 'success'} onClose={() => setOpenSnackBar(false)}>
              {error ? t(formatMessages(error.message)) : t(formatMessages(buyResult.message))}
            </Alert>
          </Snackbar>
        </div>
      );
    }
  }
};

export default Index;
