import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import email from '../../assets/img/icons/email.svg';
import Badge from '@material-ui/core/Badge';
import { getMessages } from '../../redux/slices/messages';
import { Typography } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import * as dayjs from 'dayjs';

const useStyles = makeStyles((theme) => ({
  dropdown: {
    marginLeft: '8px',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
}));

const Index = ({ userId }) => {
  const { t } = useTranslation();
  const { messages } = useSelector((state) => state.messages);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  let nbMessages=0;
  let a;
  messages && messages?.length &&(
      nbMessages=messages.filter(message=>!message.seen).length
  );

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  React.useEffect(() => {
    if(userId) {
      dispatch(getMessages(userId));
    }
  }, [userId]);

  return (
    <div className={classes.dropdown + ' message'}>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge color="secondary" badgeContent={nbMessages} showZero>
          <img alt="message" src={email} className="message-icon" />
        </Badge>
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              marginTop: '25px',
              marginRight: '200px',
              width: '250px',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <Typography className="menu-item-title">{t('Messages')}</Typography>
                  {messages && messages.length ? (
                    messages.map((message,index) => (
                    <MenuItem onClick={handleClose} style={{backgroundColor:!message.seen && 'rgba(233, 248, 253, 0.6)'}}>
                        <Link to={`/inbox/${message._id}`} key={message._id}>
                          <div className="message-header">
                            {message.seen === false ? (
                              <VisibilityOffIcon style={{ color: 'gray' }} />
                            ) : (
                              <VisibilityIcon style={{ color: 'gray' }} />
                            )}
                            <Typography className="message-title"> {message.message.name}</Typography>
                          </div>
                          <div className="group-message" >
                            <Typography className="message-body">
                              {/* {message.message.body.replace(/<[^>]+>/g, '')} */}
                              Takiacademy
                            </Typography>
                            <Typography className="message-time">
                              {dayjs(message.message.created_at).format('hh:mm')}
                            </Typography>
                          </div>
                        </Link>
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem onClick={handleClose}>{t("Pas de messages")}</MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default Index;
